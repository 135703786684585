import { Recipient } from './../store/index';
import axios from "axios"
import {
  Academy,
  App,
  Auth,
  Comment,
  Course,
  Message,
  Thread,
  User,
} from "@/store"

export const api = () => {
  let APIURL = "https://portal.skillution.com"
  if (location.host.includes("192.168")) {
    APIURL = "https://portal.skillution.com.ddev.site"
  }

  const getCsrfToken = () => {
    return axios.get(APIURL + "/session/token").then(({ data }) => {
      return data
    })
  }

  const loadApp = async (appId: string) => {
    return await axios
      .get(APIURL + "/api/app?_format=json", {
        params: {
          hostname: location.hostname,
          id: appId,
        },
      })
      .then(({ data }) => {
        if (data.id) {
          const app: App = {
            id: data.id,
            logo: data.logo,
            features: {
              community: data.features.community,
              share: data.features.share,
              bookmark: data.features.bookmark,
              rating: data.features.rating,
              privateMessages: data.features.private_messages
            },
            colors: {
              headlines: data.colors.headlines,
              background: data.colors.background,
              text: data.colors.text,
              primary: data.colors.primary,
              primaryContrast: data.colors.primary_contrast,
              secondary: data.colors.secondary,
              secondaryContrast: data.colors.secondary_contrast,
              tertiary: data.colors.tertiary,
              tertiaryContrast: data.colors.tertiary_contrast,
              cardBackground: data.colors.card_background,
            },
            startScreen: {
              backgroundImageDesktop: data.start_screen.background_image_desktop,
              backgroundImageMobile: data.start_screen.background_image_mobile,
              backgroundColor: data.start_screen.background_color,
              headline: data.start_screen.headline,
              subHeadline: data.start_screen.sub_headline,
              registrationEnabled: data.start_screen.registration_enabled,
              registrationButtonText: data.start_screen.registration_button_text,
              loginButtonText: data.start_screen.login_button_text,
              registrationButtonSize: data.start_screen.registration_button_size,
              loginButtonSize: data.start_screen.login_button_size,
              registrationButtonFill: data.start_screen.registration_button_fill,
              loginButtonFill: data.start_screen.login_button_fill,
              contentMarkup: data.start_screen.content_markup,
              content: data.start_screen.content
            },
            schemes: {
              tabBar: data.schemes.tab_bar,
              toolBar: data.schemes.tool_bar,
              registrationButton: data.schemes.registration_button,
              loginButton: data.schemes.login_button,
            },
            admin: {
              autoUpdate: data.admin.auto_update,
              autoUpdateInterval: data.admin.auto_update_interval,
            },
            academies: data.academies,
            currentAcademy: data.academies[0],
            menus: data.menus
          }
          return app
        }
        throw new Error('No App found');
      })
  }

  const login = async (username: string, password: string) => {
    return await axios
      .post(APIURL + "/user/login?_format=json", {
        name: username,
        pass: password
      })
      .then(({ data }) => {
        const auth: Auth = {
          uid: data.current_user.uid,
          name: data.current_user.name,
          csrfToken: data.csrf_token,
          logoutToken: data.logout_token,
          accessToken: data.access_token,
          isAuthenticated: true,
        }
        return auth
      })
  }

  const signUp = async (username: string, password: string, appId: number) => {
    return getCsrfToken().then((csrfToken) => {
      return axios.post(APIURL + "/api/skillution/register?_format=json",
        {
          name: username,
          pass: password,
          appId: appId
        },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
    })
  }

  const loginStatus = async (accessToken: string) => {
    return await axios
      .get(APIURL + "/user/login_status?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const getContactPerson = async (academy: Academy, accessToken: string) => {
    return await axios
      .get(APIURL + "/api/app/contact-person?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        params: {
          academy: academy,
        },
      })
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        console.log(error.toJSON())
      })
  }

  const loadFeed = async (accessToken: string, appId: string, academyUuid: string, groupUuid: string | null) => {
    return await axios.get(APIURL + "/api/post/?_format=json", {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      params: {
        appId: appId,
        academyUuid: academyUuid,
        groupUuid: groupUuid
      },
    }).then(({ data }) => {
      return data
    })
  }

  const getCourses = async (accessToken: string, appId: string) => {
    const courses: Course[] = []
    const categories: any[] = []
    await axios
      .get(APIURL + "/api/courses?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        params: {
          appId: appId,
        },
      })
      .then(({ data }) => {
        for (let i = 0; i < data.courses.length; i++) {
          const course = data.courses[i]
          for (let iC = 0; iC < course.categories.length; iC++) {
            courses.push({
              uuid: course.uuid,
              title: course.title,
              thumbnailSmall: course.thumbnailSmall,
              thumbnailLarge: course.thumbnailLarge,
              author: {
                uuid: course.author.uuid,
                firstName: course.author.firstName,
                lastName: course.author.lastName,
                role: course.author.role,
                position: course.author.position,
                pictureUrl: course.author.pictureUrl,
              },
              duration: course.duration,
              category: course.categories[iC],
              units: [],
              unitsCount: course.unitsCount,
              events: [],
              progress: parseInt(course.progress) / 100,
              lastAccess: parseInt(course.lastAccess),
              lastUnit: course.lastUnit,
              activeBooking: course.activeBooking,
              published: course.published,
              teaser: course.teaser,
              publishDate: course.publishDate
            })
          }
        }
        for (let i = 0; i < data.categories.length; i++) {
          categories.push(data.categories[i])
        }
      })
      .catch((error) => {
        console.log(error)
      })
    return { courses: courses, categories: categories }
  }

  const setProgress = async (
    accessToken: string,
    courseUuid: string,
    unitUuid: string
  ) => {
    return getCsrfToken().then((csrfToken) => {
      return axios.post(
        APIURL + "/api/course/" + courseUuid + "/progress?_format=json",
        {
          unitUuid: unitUuid,
          studentId: 6,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-CSRF-Token": csrfToken,
          },
        }
      )
    })
  }

  const getCourse = async (accessToken: string, courseId: any) => {
    return axios
      .get(APIURL + "/api/course/" + courseId + "?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        // Add Demo content
        // const demoUnit = data.units[2]
        // demoUnit.type = "webinar"
        // demoUnit.date = "05.11.2023 16 Uhr bis 18:30 Uhr"
        // data.units.push(demoUnit)

        // const demoUnit2 = data.units[3]
        // demoUnit2.type = "1to1"
        // demoUnit2.date = "05.11.2023 16 Uhr bis 18:30 Uhr"
        // data.units.push(demoUnit2)

        const course: Course = data
        return course
      })
  }

  const getEvents = async (accessToken: string, academyUuid: string) => {
    return await axios
      .get(APIURL + "/api/events?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        params: {
          academyUuid: academyUuid,
        },
      })
      .then(({ data }) => {
        return data
      })

  }

  const bookmark = async (courseId: string) => {
    await axios.post(
      APIURL + "/entity/flagging",
      {
        flag_id: "bookmark",
        entity_type: "course",
        entity_id: courseId,
      },
      {
        headers: {
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2ODU1NjYxNjMsImV4cCI6MTY4NTU2OTc2MywiZHJ1cGFsIjp7InVpZCI6IjUifX0.qf_X4zcmQvACxEw8HiKwsjaRHDevyvfv70rDk0hRWMJlbj98orXS7yObjPrzbX5hE8dQgUnFtlCqc6qN4xNIqEzfehAgCigI3ZfieADjc12Qc0Ptk1qtmHpOzty-9d-sn84yVjoxJFR24mZhNrJNh_oj45ayVyrDS8w8GzLU2TPOfgB0JEw3gd1927LMmaH6VWLMXS5DI8MeUabd-i3NdJuCB0PXLjdU6MhlM-IEh9C12y0pxWI8fK2qJuDkvpuNsN4xUGrx7qIO6K3HjITkBNPFxwwy8D5TUXJT1DnogbvcxLlBQyOgv5m1wGo2qnPl1IUj_etozCuF3fDAhThudw",
          "X-CSRF-Token": "vmS_76vW5xzB7ejDcBswPr9kJL7PzD8HinoIIrdsyy8",
        },
      }
    )
  }

  const getThreads = async (accessToken: string, academyUuid: string) => {
    return await axios
      .get(APIURL + "/api/threads?_format=json", {
        params: {
          academyUuid: academyUuid,
        },
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        const threads: Thread[] = []
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          threads.push({
            uuid: item.uuid,
            id: item.id,
            created: item.created,
            lastMessageTime: item.lastMessageTime,
            lastMessageUserId: item.lastMessageUserId,
            messages: [],
            recipients: item.recipients,
            unread: item.unread
          })
        }
        return threads
      }).catch(() => {
        const threads: Thread[] = []
        return threads
      })
  }

  const getMessages = async (threadUuid: string | string[], accessToken: string) => {
    return await axios
      .get(APIURL + "/api/thread/" + threadUuid + "/messages?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        const messages: Message[] = []
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          messages.push({
            id: item.id,
            uuid: item.uuid,
            threadId: item.threadId,
            text: item.text,
            read: item.read,
            author: item.author,
          })
        }
        return messages
      })
  }

  const getMessageRecipients = async (academyUuid: string, searchString: string, accessToken: string) => {
    return await axios
      .get(APIURL + "/api/message/recipients?_format=json", {
        params: {
          academyUuid: academyUuid,
          searchString: searchString
        },
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        const recipients: Recipient[] = []
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          recipients.push({
            uuid: item.uuid,
            firstName: item.firstName,
            lastName: item.lastName,
            pictureUrl: item.pictureUrl
          })
        }
        return recipients
      })
  }

  const getMessageRecipient = async (academyUuid: string, recipientUuid: string | [], accessToken: string) => {
    return await axios
      .get(APIURL + "/api/message/recipients?_format=json", {
        params: {
          academyUuid: academyUuid,
          recipientUuid: recipientUuid
        },
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        const recipient: Recipient = data
        return recipient
      })
  }

  const createMessage = async (
    text: string,
    threadUuid: string | string[],
    accessToken: string
  ) => {
    return getCsrfToken().then((csrfToken: string) => {
      return axios
        .post(
          APIURL + "/api/thread/" + threadUuid + "/message?_format=json",
          {
            text: text,
            threadUuid: threadUuid
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-CSRF-Token": csrfToken,
            },
          }
        )
        .then(({ data }) => {
          return data
        })
    })
  }

  const markMessageAsRead = async (
    messageUuid: string | string[],
    accessToken: string
  ) => {
    return getCsrfToken().then((csrfToken: string) => {
      return axios
        .post(
          APIURL + "/api/message/read?_format=json",
          {
            messageUuid: messageUuid
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-CSRF-Token": csrfToken,
            },
          }
        )
        .then(({ data }) => {
          return data
        })
    })
  }

  const getUser = async (uuid: string, accessToken: string) => {
    return await axios
      .get(APIURL + "/api/skillution/profile/" + uuid + "?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {

        const user: User = {
          id: data.uuid,
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
          eMail: data.eMail,
          city: data.city,
          street: data.street,
          postalCode: data.postalCode,
          pictureUrl: data.pictureUrl,
          about: data.about,
          position: data.position
        }
        return user
      })
  }

  const getProfile = async (accessToken: string) => {
    return await axios
      .get(APIURL + "/api/app/profile?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const updateProfile = async (profile: User, accessToken: string) => {
    return await axios
      .patch(APIURL + "/api/app/profile?_format=json", profile, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const updateProfilePicture = async (picture: object, accessToken: string) => {
    return await axios
      .patch(APIURL + "/api/app/profilepicture?_format=json", {
        picture: picture
      }, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const updatePassword = async (
    uid: any,
    passwordExisting: string,
    password: string,
    accessToken: string
  ) => {
    return getCsrfToken().then((csrfToken) => {
      return axios.patch(
        APIURL + "/user/" + uid + "?_format=json",
        {
          pass: [{ existing: passwordExisting, value: password }],
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-CSRF-Token": csrfToken,
          },
        }
      )
    })
  }

  const saveComment = async (
    text: string,
    courseId: number,
    accessToken: string
  ) => {
    return getCsrfToken().then((csrfToken) => {
      return axios
        .post(
          APIURL + "/comment?_format=json",
          {
            entity_id: [{ target_id: courseId }],
            entity_type: [{ value: "course" }],
            comment_type: [{ target_id: "course_comment" }],
            comment_body: [{ value: text }],
            field_name: [{ value: "field_comments" }],
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-CSRF-Token": csrfToken,
            },
          }
        )
        .then(({ data }) => {
          return getUser(data.uid[0].target_id, accessToken).then((user) => {
            const comment: Comment = {
              id: data.cid[0].value,
              courseId: courseId,
              text: data.comment_body[0].value,
              author: {
                uuid: '1',
                firstName: user.firstName,
                lastName: user.lastName,
                role: user.role,
                position: user.position,
                pictureUrl: user.pictureUrl,
              },
            }
            return comment
          })
        })
    })
  }

  const getComments = async (courseId: number, accessToken: string) => {
    return getCsrfToken().then((csrfToken) => {
      return axios
        .get(APIURL + "/course/" + courseId + "/comments/list?_format=json", {
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-CSRF-Token": csrfToken,
          },
        })
        .then(({ data }) => {
          const comments: Comment[] = []
          for (let i = 0; i < data.length; i++) {
            const comment = data[i]
            comments.push({
              id: comment.cid[0].value,
              courseId: courseId,
              text: comment.comment_body[0].value,
              author: {
                uuid: '1',
                firstName: "Max",
                lastName: "Mustermann",
                role: 'student',
                position: "Position",
                pictureUrl:
                  "https://portal.skillution.com/sites/default/files/user/2023-06/christopher-campbell-rDEOVtE7vOs-unsplash.jpg",
              },
            })
          }
          return comments
        })
    })
  }

  const createThread = async (academy: Academy, recipients: Recipient[], accessToken: string) => {
    return getCsrfToken().then((csrfToken) => {
      return axios
        .post(
          APIURL + "/api/message/thread?_format=json",
          {
            academy: academy,
            recipients: recipients,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-CSRF-Token": csrfToken,
            },
          }
        )
        .then(({ data }) => {
          return data
        })
    })
  }

  const savePushToken = async (token: string, appId: string) => {
    return axios.post(APIURL + "/api/push-token", {
      token: token,
      app: appId,
    })
  }

  const getChallenges = async (accessToken: string, academyUuid: string) => {
    return await axios
      .get(APIURL + "/api/challenges?_format=json", {
        params: {
          academyUuid: academyUuid,
        },
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const joinChallenge = async (accessToken: string, challengeUuid: string) => {
    return await axios
      .get(APIURL + "/api/challenge/" + challengeUuid + "/join?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const leaveChallenge = async (accessToken: string, challengeUuid: string) => {
    return await axios
      .get(APIURL + "/api/challenge/" + challengeUuid + "/leave?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const saveChallengeResult = async (
    accessToken: string,
    challengeUuid: string,
    result: object
  ) => {
    return getCsrfToken().then((csrfToken) => {
      return axios.post(
        APIURL + "/api/challenge/result?_format=json",
        {
          challengeUuid: challengeUuid,
          result: result
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-CSRF-Token": csrfToken,
          },
        }
      )
    })
  }

  const getPage = async (appId: string, pageUuid: string) => {
    return await axios
      .get(APIURL + "/api/pages/page/" + pageUuid + "?_format=json")
      .then(({ data }) => {
        return data
      })
  }

  const getPages = async (academyUuid: string) => {
    return await axios
      .get(APIURL + "/api/pages/?_format=json", {
        params: {
          academyUuid: academyUuid,
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  const loadLiveRooms = async (accessToken: string, academyUuid: string) => {
    return await axios
      .get(APIURL + "/api/live/?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        params: {
          academyUuid: academyUuid,
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  const getGroups = async (accessToken: string, academyUuid: string) => {
    return await axios
      .get(APIURL + "/api/groups/?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        params: {
          academyUuid: academyUuid,
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  const joinGroup = async (accessToken: string, groupUuid: string) => {
    return await axios
      .get(APIURL + "/api/groups/" + groupUuid + "/join?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const leaveGroup = async (accessToken: string, groupUuid: string) => {
    return await axios
      .get(APIURL + "/api/groups/" + groupUuid + "/leave?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const createPost = async (accessToken: string, academy: Academy, post: any) => {
    return getCsrfToken().then((csrfToken) => {
      return axios
        .post(
          APIURL + "/api/post?_format=json",
          {
            academy: academy,
            post: post
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-CSRF-Token": csrfToken,
            },
          }
        )
        .then(({ data }) => {
          return data
        })
    })
  }

  const likePost = async (accessToken: string, postUuid: string | null) => {
    return await axios
      .get(APIURL + "/api/post/" + postUuid + "/like?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const unlikePost = async (accessToken: string, postUuid: string | null) => {
    return await axios
      .get(APIURL + "/api/post/" + postUuid + "/unlike?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const deletePost = async (accessToken: string, postUuid: string | null) => {
    return await axios
      .delete(APIURL + "/api/post/" + postUuid + "/delete?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const attendEvent = async (accessToken: string, eventUuid: string | null) => {
    return await axios
      .get(APIURL + "/api/events/" + eventUuid + "/attend?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }

  const leaveEvent = async (accessToken: string, eventUuid: string | null) => {
    return await axios
      .get(APIURL + "/api/events/" + eventUuid + "/leave?_format=json", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(({ data }) => {
        return data
      })
  }



  return {
    attendEvent,
    leaveEvent,
    createMessage,
    createPost,
    deletePost,
    loadApp,
    loadFeed,
    login,
    loginStatus,
    getChallenges,
    getComments,
    getContactPerson,
    getCourses,
    getCourse,
    getEvents,
    getGroups,
    getProfile,
    getThreads,
    getMessages,
    getMessageRecipient,
    getMessageRecipients,
    getPage,
    getPages,
    getUser,
    joinChallenge,
    leaveChallenge,
    saveChallengeResult,
    joinGroup,
    leaveGroup,
    loadLiveRooms,
    likePost,
    unlikePost,
    markMessageAsRead,
    setProgress,
    updatePassword,
    updateProfile,
    updateProfilePicture,
    bookmark,
    saveComment,
    createThread,
    savePushToken,
    signUp
  }
}
