import { createRouter, createWebHistory } from "@ionic/vue-router"
import { RouteRecordRaw } from "vue-router"
import { useAuthStore } from "@/store"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/start",
  },
  {
    path: "/start",
    name: "Start",
    component: () => import("@/views/StartScreen.vue"),
    meta: { protected: false },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LogIn.vue"),
    meta: { protected: false },
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/views/SignUp.vue"),
    meta: { protected: false },
  },
  {
    path: "/academies",
    name: "Academies",
    component: () => import("@/views/AcademiesPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/community",
    name: "Community",
    component: () => import("@/views/CommunityPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/challenges",
    name: "Challenges",
    component: () => import("@/views/ChallengesPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/challenges/:uuid",
    component: () => import("@/views/ChallengePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/courses",
    name: "Courses",
    component: () => import("@/views/CoursesPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/course/:course",
    component: () => import("@/views/CoursePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/page/:uuid",
    component: () => import("@/views/PagePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/search",
    component: () => import("@/views/SearchPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/messages",
    name: "Messages",
    component: () => import("@/views/MessagesPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/messages/:threadUuid",
    name: "Message",
    component: () => import("@/views/MessagePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/message/new",
    name: "New Message",
    component: () => import("@/views/CreateMessagePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/message/new/:recipientUuid",
    name: "New Message to Recipient",
    component: () => import("@/views/CreateMessagePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/profile",
    name: "OwnProfile",
    component: () => import("@/views/ProfilePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/my-challenges",
    name: "MyChallenges",
    component: () => import("@/views/MyChallenges.vue"),
    meta: { protected: true },
  },
  {
    path: "/my-courses",
    name: "MyCourses",
    component: () => import("@/views/MyCourses.vue"),
    meta: { protected: true },
  },
  {
    path: "/profile/:userId",
    name: "UserProfile",
    component: () => import("@/views/ProfilePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/SettingsPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/info",
    name: "Info",
    component: () => import("@/views/InfoPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/group/:groupId",
    name: "Group",
    component: () => import("@/views/GroupPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("@/views/EventsPage.vue"),
    meta: { protected: true },
  },
  {
    path: "/live",
    name: "Live",
    component: () => import("@/views/LivePage.vue"),
    meta: { protected: true },
  },
  {
    path: "/live/:liveUuid",
    name: "LiveRoom",
    component: () => import("@/views/LiveRoomPage.vue"),
    meta: { protected: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to) => {
  const authStore = useAuthStore()
  if (!authStore.isAuthenticated) await authStore.init()
  if (to.meta.protected && !authStore.isAuthenticated) {
    return { name: "Start" }
  }
  if (to.name == "Login" && authStore.isAuthenticated) {
    return '/courses'
  }
})

export default router
