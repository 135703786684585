<template>
  <ion-menu id="menu"
    menu-id="menu"
    type="overlay"
    side="end"
    content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Mein Account</ion-title>
        <ion-buttons slot="end">
          <ion-menu-toggle>
            <ion-button><ion-icon
                :icon="closeCircleOutline"></ion-icon></ion-button>
          </ion-menu-toggle>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list lines="full">
        <ion-menu-toggle>
          <ion-item button
            @click="router.push({ name: 'UserProfile', params: { userId: profileStore.getUuid } })"
            detail="true">
            <ion-icon :icon="personOutline"
              slot="start"></ion-icon>
            <ion-label>Öffentliches Profil</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item button
            @click="router.push({ name: 'MyChallenges' })"
            detail="true">
            <ion-icon :icon="medalOutline"
              slot="start"></ion-icon>
            <ion-label>Meine Challenges</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item button
            @click="goto()"
            detail="true">
            <ion-icon :icon="settingsOutline"
              slot="start"></ion-icon>
            <ion-label>Einstellungen</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!--
        <ion-item button
          detail="true">
          <ion-icon :icon="cardOutline"
            slot="start"></ion-icon>
          <ion-label>Abrechnungen</ion-label>
        </ion-item>
        -->
        <!--
        <ion-item button
          detail="true">
          <ion-icon :icon="documentLockOutline"
            slot="start"></ion-icon>
          <ion-label>Datenschutz</ion-label>
        </ion-item>
        -->
        <ion-menu-toggle v-if="appStore.academies.length > 1">
          <ion-item button
            detail="true"
            @click="router.push({ name: 'Academies' })">
            <ion-icon :icon="syncOutline"
              slot="start"></ion-icon>
            <ion-label>Akademie wechseln</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-item button
          v-for="menuItem in appStore.getSystemMenu"
          :key="menuItem.uuid"
          detail="true"
          @click="gotoMenuItem(menuItem)">
          <ion-icon :icon="documentLockOutline"
            slot="start"></ion-icon>
          <ion-label>{{ menuItem.label }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-list lines="none">
      <ion-item>
        <ion-icon @click="router.push('/info')"
          :icon="informationCircleOutline"
          slot="start"></ion-icon>
        <ion-label>
          App Version: {{ VERSION }}
          <p>{{ DATE }}</p>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-button size="large"
      @click="logout()"
      class="ion-align-items-baseline">Logout</ion-button>
  </ion-menu>
</template>

<script setup lang="ts">
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuToggle,
  IonButton,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/vue"
import {
  closeCircleOutline,
  documentLockOutline,
  informationCircleOutline,
  settingsOutline,
  personOutline,
  syncOutline,
  medalOutline
} from "ionicons/icons"
import { useAuthStore, useAppStore, useProfileStore } from "@/store"
import { VERSION, DATE } from "@/version"

const authStore = useAuthStore()
const appStore = useAppStore()
const profileStore = useProfileStore()

const router = useRouter()
import { useRouter } from "vue-router"

const logout = () => {
  authStore.logout().then(() => {
    router.push({ name: "Start" })
  })
}

const goto = () => {
  router.push({ name: "Settings" })
}

const gotoMenuItem = (menuItem) => {
  if (menuItem.type === 'internal') {
    router.push(menuItem.url)
  }
  if (menuItem.type === 'external') {
    window.open(menuItem.url, '_blank')
  }
}

</script>
