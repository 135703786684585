// store.ts
import { defineStore } from "pinia"
import { api } from "@/api"
import { Preferences } from "@capacitor/preferences"
import { Event } from "@ionic/core/dist/types/stencil-public-runtime"
import { File } from "@/types"

// define your typings for the store state

export type App = {
  id: string
  logo: string
  features: Features
  colors: Color
  schemes: ColorScheme
  startScreen: StartScreen
  admin: AdminAppSettings
  academies: Academy[]
  currentAcademy: Academy | null
  menus: {
    system: MenuItem[],
    tabs: MenuItem[]
  }
}

export type Academy = {
  uuid: string
  name: string
  description: string
}

export type Features = {
  community: boolean
  share: boolean
  bookmark: boolean
  rating: boolean
  privateMessages: boolean
}

export type AdminAppSettings = {
  autoUpdate: boolean
  autoUpdateInterval: number
}

export type StartScreen = {
  backgroundImageDesktop: string
  backgroundImageMobile: string
  backgroundColor: string
  headline: string
  subHeadline: string
  registrationEnabled: boolean
  registrationButtonText: string
  loginButtonText: string
  registrationButtonSize: string
  loginButtonSize: string
  registrationButtonFill: string
  loginButtonFill: string
  contentMarkup: string
  content: []
}

export type Auth = {
  uid: string
  name: string
  accessToken: string
  csrfToken: string
  logoutToken: string
  isAuthenticated: boolean
}

export type Author = {
  uuid: string,
  firstName: string
  lastName: string
  role: string
  position: string
  pictureUrl: string | null
}

export type Color = {
  headlines: string
  background: string
  text: string
  primary: string
  primaryContrast: string
  secondary: string
  secondaryContrast: string
  tertiary: string
  tertiaryContrast: string
  cardBackground: string
}

export type ColorScheme = {
  tabBar: string
  toolBar: string
  registrationButton: string
  loginButton: string
}

export type Comment = {
  id: number
  courseId: number
  text: string
  author: Author
}

export type Course = {
  uuid: string
  title: string
  thumbnailSmall: string
  thumbnailLarge: string
  author: Author
  duration: string
  units: CourseUnit[]
  unitsCount: number
  events: Event[]
  progress: number
  category: string
  activeBooking: boolean
  lastAccess: number
  lastUnit: string
  published: boolean
  teaser: boolean
  publishDate: string | null
}

export type CourseUnit = {
  uuid: string
}

export type Category = {
  title: string
}

export type Challenge = {
  uuid: string
  title: string
  description: string
  image: string
  start: string
  stop: string
  joined: boolean
  series: Series[]
  showDate: boolean
  documents: File[]
  teaser: boolean
  published: boolean
  status: string
}

export type Event = {
  uuid: string
  title: string
  start: string
  end: string
  locationTitle: string
  online: boolean
  subscribed: boolean
  description: string
}

export type Media = {
  uuid: string
  type: string
  url: string
}

export type Message = {
  id: number
  uuid: string
  threadId: number
  text: string
  author: Author
  read: boolean
}

export type Post = {
  uuid: string | null
  group: string | null
  date: string | null
  text: string
  media: Media[]
  liked: boolean
  totalLikes: number
  isOwnPost: boolean
  replyTo: string | null
  replies: Post[]
  author: Author
}

export type Recipient = {
  uuid: string
  firstName: string
  lastName: string
  pictureUrl: string
}

export type State = {
  app: App
  auth: Auth
  user: User
  courses: Course[]
  events: Event[]
  feed: Post[]
}

export type Thread = {
  uuid: string
  id: number
  created: string
  lastMessageTime: string
  lastMessageUserId: string
  messages: Message[]
  recipients: Recipient[]
  unread: number
}

export type User = {
  id: number
  firstName: string
  lastName: string
  role: string
  eMail: string
  city: string
  street: string
  position: string
  postalCode: string
  pictureUrl: string
  about: string
}

export type Trainer = {
  id: number
  firstName: string
  lastName: string
  pictureUrl: string
}

export type AppError = {
  show: boolean
  message: string
}

export type Page = {
  uuid: string
  label: string
  public: boolean
  systemPage: string | null
  content: []
}

export type MenuItem = {
  uuid: string
  type: string
  label: string
  icon: null | string
  url: string
}

export type Series = {
  uuid: string
  label: string
  weight: number
}

export type Group = {
  uuid: string
  label: string
  description: string
  members: number
  joined: boolean
}

export type LiveRoom = {
  uuid: string
  title: string
  description: string
  start: string
  end: string
  private: boolean
}

export const useAppStore = defineStore("app", {
  state: () => ({
    id: "",
    logo: "",
    features: {
      community: false,
      share: false,
      bookmark: false,
      rating: false,
      privateMessages: false
    },
    colors: {
      headlines: "",
      background: "",
      text: "",
      primary: "",
      primaryContrast: "",
      secondary: "",
      secondaryContrast: "",
      tertiary: "",
      tertiaryContrast: "",
      cardBackground: "",
    },
    startScreen: {
      backgroundImageDesktop: "",
      backgroundImageMobile: "",
      backgroundColor: "#FFFFFF",
      headline: "",
      subHeadline: "",
      registrationEnabled: false,
      registrationButtonText: "",
      loginButtonText: "",
      registrationButtonSize: "default",
      loginButtonSize: "default",
      registrationButtonFill: "solid",
      loginButtonFill: "solid",
    },
    schemes: {
      tabBar: "primary",
      toolBar: "primary",
      registrationButton: "primary",
      loginButton: "secondary",
    },
    admin: {
      liveUpdate: false,
      liveUpdateInterval: 2000,
    },
    academies: [] as Academy[],
    menus: {
      system: [] as MenuItem[],
      tabs: [] as MenuItem[]
    },
    currentAcademy: {} as Academy,
    interval: 0,
    error: {
      show: false,
      message: ''
    }
  }),
  getters: {
    getAppId: (state) => state.id,
    getCurrentAcademy: (state) => state.currentAcademy,
    getSystemMenu: (state) => state.menus.system,
    getTabs: (state) => { state.menus.tabs },
    share: (state) => state.features.share,
    bookmark: (state) => state.features.bookmark,
    rating: (state) => state.features.rating,
    privateMessages: (state) => state.features.privateMessages,
  },
  actions: {
    async initApp() {
      const { loadApp } = api()
      return loadApp(this.id).then((app) => {
        if(app) {
          this.id = app.id
          this.logo = app.logo
          this.features = app.features
          this.colors = app.colors
          this.schemes = app.schemes
          this.startScreen = app.startScreen
          this.academies = app.academies
          this.currentAcademy = app.academies[0]
          this.menus = app.menus
          //
          const root = document.documentElement
          root.style.setProperty("--ion-color-primary", app.colors.primary)
          root.style.setProperty(
            "--ion-color-primary-contrast",
            app.colors.primaryContrast
          )
          root.style.setProperty("--ion-color-secondary", app.colors.secondary)
          root.style.setProperty(
            "--ion-color-secondary-contrast",
            app.colors.secondaryContrast
          )
          root.style.setProperty("--ion-color-tertiary", app.colors.tertiary)
          root.style.setProperty(
            "--ion-color-tertiary-contrast",
            app.colors.tertiaryContrast
          )
          root.style.setProperty("--ion-color-quaternary", "#FF0000")
          root.style.setProperty("--ion-text-color", app.colors.text)
          root.style.setProperty("--color-headlines", app.colors.headlines)
        }
        if (app.admin.autoUpdate && this.interval == 0) {
          this.interval = setInterval(() => {
            this.initApp()
          }, app.admin.autoUpdateInterval)
        }
        if (!app.admin.autoUpdate && this.interval > 0) {
          clearInterval(this.interval)
        }
      }).catch(() => {
        this.error.show = true
        this.error.message = 'Es konnte keine Web-App geladen werden'
      })
    },
    async switchAcademy(academy: Academy) {
      this.currentAcademy = academy
    }
  },
})

export const useAuthStore = defineStore("auth", {
  state: () => ({
    uid: "",
    name: "",
    accessToken: "",
    csrfToken: "",
    logoutToken: "",
    isAuthenticated: false,
  }),
  actions: {
    login(credentials: any) {
      const { login } = api()
      return login(credentials.username, credentials.password)
        .then(async (auth) => {
          await Preferences.set({
            key: "auth",
            value: JSON.stringify(auth),
          })
          this.uid = auth.uid
          this.name = auth.name
          this.accessToken = auth.accessToken
          this.csrfToken = auth.csrfToken
          this.logoutToken = auth.logoutToken
          this.isAuthenticated = true
        })
        .then(() => {
          const courseStore = useCourseStore()
          courseStore.getCourses()
          courseStore.getBookmarkedCourses()
          const profileStore = useProfileStore()
          profileStore.loadContactPerson()
          profileStore.loadProfile()

          const challengeStore = useChallengeStore()
          challengeStore.getChallenges()
        })
    },
    async init() {
      const { value } = await Preferences.get({ key: "auth" })
      if (value) {
        const auth = JSON.parse(value)
        this.uid = auth.uid
        this.name = auth.name
        this.accessToken = auth.accessToken
        this.csrfToken = auth.csrfToken
        this.logoutToken = auth.logoutToken
        this.isAuthenticated = auth.isAuthenticated
        return auth
      }
    },
    async logout() {
      this.uid = ""
      this.name = ""
      this.accessToken = ""
      this.csrfToken = ""
      this.logoutToken = ""
      this.isAuthenticated = false
      await Preferences.set({
        key: "auth",
        value: JSON.stringify({
          uid: "",
          name: "",
          accessToken: "",
          csrfToken: "",
          logoutToken: "",
          isAuthenticated: false,
        }),
      })
    },
    checkLogin() {
      const { loginStatus } = api()
      return loginStatus(this.accessToken).then((status) => {
        if (!status) {
          return this.logout()
        } else {
          return status
        }
      })
    },
  },
})

export const useProfileStore = defineStore("profile", {
  state: () => ({
    loaded: false,
    uuid: 0,
    id: 0,
    firstName: "",
    lastName: "",
    eMail: "",
    city: "",
    street: "",
    position: "",
    postalCode: "",
    role: "",
    pictureUrl: null,
    about: "",
    contactPerson: {
      show: false,
      trainer: null,
    },
  }),
  getters: {
    getUuid: (state) => state.uuid,
    getPictureUrl: (state) => state.pictureUrl,
    hasLoaded: (state) => state.loaded,
    role: (state) => state.role,
    position: (state) => state.position
  },
  actions: {
    async loadContactPerson() {
      const { getContactPerson } = api()
      const authStore = useAuthStore()
      const appStore = useAppStore()
      return getContactPerson(appStore.currentAcademy, authStore.accessToken).then((contactPerson) => {
        if (contactPerson && contactPerson.id) {
          this.contactPerson.show = true
          this.contactPerson.trainer = contactPerson
        } else {
          this.contactPerson.show = false
          this.contactPerson.trainer = null
        }
      })
    },
    async loadProfile() {
      const { getProfile } = api()
      const authStore = useAuthStore()
      return getProfile(authStore.accessToken).then((profile) => {
        this.id = profile.id
        this.uuid = profile.uuid
        this.firstName = profile.firstName
        this.lastName = profile.lastName
        this.eMail = profile.eMail
        this.pictureUrl = profile.pictureUrl
        this.about = profile.about
        this.loaded = true
        return profile
      })
    },
    async updateProfile(profile: any) {
      const { updateProfile } = api()
      const authStore = useAuthStore()
      updateProfile(profile, authStore.accessToken).then(() => {
        // this.profile = profile
      })
    },
  },
})

export const useEventsStore = defineStore("events", {
  state: () => ({
    events: [] as Event[],
  }),
  getters: {},
  actions: {
    async loadEvents() {
      const { getEvents } = api()
      const authStore = useAuthStore()
      const appStore = useAppStore()
      return getEvents(authStore.accessToken, appStore.currentAcademy.uuid).then((data: Event[]) => {
        this.events = data
        return this.events
      })
    },
    async attend(eventUuid: string) {
      const { attendEvent } = api()
      const authStore = useAuthStore()
      attendEvent(authStore.accessToken, eventUuid).then(() => {
        const groupIndex = this.events.findIndex((item) => item.uuid === eventUuid)
        this.events[groupIndex].subscribed = true
      })
    },
    async leave(eventUuid: string) {
      const { leaveEvent } = api()
      const authStore = useAuthStore()
      leaveEvent(authStore.accessToken, eventUuid).then(() => {
        const groupIndex = this.events.findIndex((item) => item.uuid === eventUuid)
        this.events[groupIndex].subscribed = false
      })
    }
  },
})

export const useUserStore = defineStore("user", {
  state: () => ({
    uuid: null,
    firstName: "",
    lastName: "",
    eMail: "",
    pictureUrl: '',
  }),
  getters: {
    getAuthor(state) {
      return {
        uuid: state.uuid,
        firstName: state.firstName,
        lastName: state.lastName,
        position: '',
        pictureUrl: state.pictureUrl
      }
    }
  },
  actions: {},
})

export const useFeedStore = defineStore("feed", {
  state: () => ({
    feed: [] as Post[],
  }),
  getters: {},
  actions: {
    getFeed() {
      const authStore = useAuthStore()
      const appStore = useAppStore()
      const { loadFeed } = api()
      loadFeed(authStore.accessToken, appStore.getAppId, appStore.getCurrentAcademy.uuid, null).then((feed) => {
        this.feed = feed
      })
    },
  },
})

export const useCourseStore = defineStore("course", {
  state: () => {
    return {
      courses: [] as Course[],
      currentCourses: [] as Course[],
      bookmarkedCourses: [] as Course[],
      categories: [] as Category[],
    }
  },
  getters: {
    getCompleted: (state) => state.courses,
    getCurrent: (state) => state.currentCourses
  },
  actions: {
    async getCourses() {
      const { getCourses } = api()
      const authStore = useAuthStore()
      const appStore = useAppStore()
      return getCourses(authStore.accessToken, appStore.id).then((data) => {
        this.courses = data.courses
        this.currentCourses = data.courses.filter(
          (item) => item.activeBooking === true && item.lastAccess > 0
        )

        this.currentCourses = [
          ...new Map(
            this.currentCourses.map((v) => [v.uuid, v])
          ).values(),
        ]

        if (this.currentCourses.length > 1) {
          this.currentCourses = this.currentCourses.sort(
            (a, b) => b.lastAccess - a.lastAccess
          )
        }

        this.categories = data.categories
      })
    },
    getBookmarkedCourses() {
      const { getCourses } = api()
      const authStore = useAuthStore()
      const appStore = useAppStore()
      getCourses(authStore.accessToken, appStore.id).then((data) => {
        this.bookmarkedCourses.push(data.courses[0])
        this.bookmarkedCourses.push(data.courses[1])
        this.bookmarkedCourses.push(data.courses[2])
      })
    },
  },
})

export const usePushStore = defineStore("push", {
  state: () => {
    return {
      token: "",
    }
  },
  getters: {},
  actions: {
    async init() {
      const { value } = await Preferences.get({ key: "pushToken" })
      if (value) {
        this.token = value
      }
    },
    async setToken(token: string) {
      this.token = token
      await Preferences.set({
        key: "pushToken",
        value: token,
      })
      const appStore = useAppStore()
      const { savePushToken } = api()
      savePushToken(token, appStore.id)
    },
  },
})

export const useMessageStore = defineStore("message", {
  state: () => {
    return {
      threads: [] as Thread[],
      interval: 0
    }
  },
  getters: {
    countUnreadMessages(state) {
      let countUnread = 0
      for(let i = 0; i < state.threads.length; i++) {
        countUnread += state.threads[i].unread
      }
      return countUnread
    }
  },
  actions: {
    async getThreads() {
      const { getThreads } = api()
      const authStore = useAuthStore()
      const appStore = useAppStore()
      if(authStore.isAuthenticated) {
          getThreads(authStore.accessToken, appStore.getCurrentAcademy.uuid).then((threadItems) => {
          this.threads = threadItems
        })
      }
    },
    startMessageService() {
      this.getThreads()
      this.interval = setInterval(() => {
        this.getThreads()
      }, 5000)
    },
    stopMessageService() {
      clearInterval(this.interval)
    }
  }
})

export const useChallengeStore = defineStore("challenge", {
  state: () => {
    return {
      challenges: [] as Challenge[],
    }
  },
  getters: {
    getJoined: (state) => state.challenges.filter((challenge) => challenge.joined === true),
    getAllChallenges(state) {
      return state.challenges
    },
    getAllChallengeByUuid(state) {
      return (uuid: string) => state.challenges.find((challenge) => challenge.uuid === uuid)
    },
    getChallengeBySeries: (state) => {
      return (seriesUuid: string) => {
        const challenges = state.challenges.filter(item => item.series.some(series => series.uuid === seriesUuid))
        return challenges.sort((a, b) => {
          const indexA = a.series.findIndex((itemA) => itemA.uuid == seriesUuid)
          const indexB = b.series.findIndex((itemB) => itemB.uuid == seriesUuid)
          return a.series[indexA].weight - b.series[indexB].weight
        })
      }
    },
    getChallengeBySeriesIndex: (state) => {
      return (seriesUuid: string, start: number, length: number) => {
        const challenges = state.challenges.filter(item => item.series.some(series => series.uuid === seriesUuid))
        const challengesSorted = challenges.sort((a, b) => {
          const indexA = a.series.findIndex((itemA) => itemA.uuid == seriesUuid)
          const indexB = b.series.findIndex((itemB) => itemB.uuid == seriesUuid)
          return a.series[indexA].weight - b.series[indexB].weight
        })
        const items = []
        if(challengesSorted.length < length) {
          length = challengesSorted.length
        }
        for (let i = start; i < length; i++) {
          items.push(challengesSorted[i])
        }
        return items
      }
    }
  },
  actions: {
    async getChallenges() {
      const { getChallenges } = api()
      const authStore = useAuthStore()
      const appStore = useAppStore()
      if(authStore.isAuthenticated) {
          return getChallenges(authStore.accessToken, appStore.getCurrentAcademy.uuid).then((challenges) => {
            this.challenges = challenges
          })
      }
    },
    async join(challengeUuid: string) {
      const { joinChallenge } = api()
      const authStore = useAuthStore()
      joinChallenge(authStore.accessToken, challengeUuid).then(() => {
        const challengeIndex = this.challenges.findIndex((item) => item.uuid === challengeUuid)
        this.challenges[challengeIndex].joined = true
      })
    },
    async leave(challengeUuid: string) {
      const { leaveChallenge } = api()
      const authStore = useAuthStore()
      leaveChallenge(authStore.accessToken, challengeUuid).then(() => {
        const challengeIndex = this.challenges.findIndex((item) => item.uuid === challengeUuid)
        this.challenges[challengeIndex].joined = false
      })
    },
    async saveResult(challengeUuid: string, result: object) {
      const { saveChallengeResult } = api()
      const authStore = useAuthStore()
      return saveChallengeResult(authStore.accessToken, challengeUuid, result).then(() => {
        console.log('challenge saved')
      })
    }
  }
})

export const usePagesStore = defineStore("pages", {
  state: () => {
    return {
      pages: [] as Page[],
    }
  },
  getters: {
    getSystemPage: (state) => {
      return (systemPage: string) => {
        return state.pages.find((page) => page.systemPage === systemPage)
      }
    }
  },
  actions: {
    async getPage(pageUuid: string) {
      const { getPage } = api()
      const appStore = useAppStore()
      return getPage(appStore.getAppId, pageUuid).then((page: any) => {
        this.pages.push(page)
        return page
      })
    },
    async getPages() {
      const { getPages } = api()
      const appStore = useAppStore()
      return getPages(appStore.getCurrentAcademy.uuid).then((pages: []) => {
        this.pages = pages
      })
    },
  }
})

export const useGroupsStore = defineStore("groups", {
  state: () => {
    return {
      groups: [] as Group[],
    }
  },
  getters: {
    getAll: (state) => state.groups,
    getJoined: (state) => state.groups.filter((group) => group.joined === true),
    getGroup: (state) => {
      return (groupUuid: string) => state.groups.find((group) => group.uuid === groupUuid)
    }
  },
  actions: {
    async getGroups() {
      const { getGroups } = api()
      const appStore = useAppStore()
      const authStore = useAuthStore()
      return getGroups(authStore.accessToken, appStore.getCurrentAcademy.uuid).then((groups: []) => {
        this.groups = groups
      }).catch(() => undefined)
    },
    async join(groupUuid: string) {
      const { joinGroup } = api()
      const authStore = useAuthStore()
      joinGroup(authStore.accessToken, groupUuid).then(() => {
        const groupIndex = this.groups.findIndex((item) => item.uuid === groupUuid)
        this.groups[groupIndex].joined = true
      })
    },
    async leave(groupUuid: string) {
      const { leaveGroup } = api()
      const authStore = useAuthStore()
      leaveGroup(authStore.accessToken, groupUuid).then(() => {
        const groupIndex = this.groups.findIndex((item) => item.uuid === groupUuid)
        this.groups[groupIndex].joined = false
      })
    }
  }
})

export const useLiveRoomStore = defineStore("liveRoom", {
  state: () => {
    return {
      rooms: [] as LiveRoom[],
    }
  },
  getters: {
    getAll: (state) => state.rooms,
    get: (state) => {
      return (roomUuid: string) => state.rooms.find((room) => room.uuid === roomUuid)
    }
  },
  actions: {
    async getLiveRooms() {
      const { loadLiveRooms } = api()
      const appStore = useAppStore()
      const authStore = useAuthStore()
      return loadLiveRooms(authStore.accessToken, appStore.getCurrentAcademy.uuid).then((liveRooms: []) => {
        this.rooms = liveRooms
      }).catch(() => undefined)
    }
  }
})
