<template>
  <ion-app>
    <main-menu v-if="!loading && authStore.isAuthenticated"></main-menu>
    <ion-router-outlet :animated="false"
      v-if="!loading && !showError"
      id="main-content" />
    <ion-alert header="🤷‍♀️ Nichts da?"
      message="Sorry, aber wir konnten unter dieser Adresse keine Akademie finden. Gründe doch einfach selbst eine!"
      :backdropDismiss="false"
      :isOpen="false"></ion-alert>

    <ion-row v-if="showError"
      class="ion-justify-content-center ion-align-items-center">
      <ion-col size="10"
        size-sm="6">
        <ion-card>
          <ion-card-header>
            <ion-card-title>🤷‍♀️ Nichts da?</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p>Sorry, aber wir konnten unter dieser Adresse keine Akademie
              finden.
              Gründe doch einfach selbst eine!</p><br>
            <p><a href="http://skillution.com">Jetzt eigene Coaching-App
                starten</a></p>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-loading :isOpen="loading"
      message="App wird geladen"> </ion-loading>

  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet, IonAlert, IonCard, IonRow, IonCol, IonCardHeader, IonCardTitle, IonCardContent, IonLoading } from "@ionic/vue"
import {
  useAppStore,
  useAuthStore,
  useProfileStore,
  useCourseStore,
  usePushStore,
  useMessageStore,
  usePagesStore,
  useChallengeStore
} from "./store"
import { useRouter } from "vue-router"
import { onMounted, ref } from "vue"
import MainMenu from "./components/System/MainMenu.vue"
import { PushNotifications } from "@capacitor/push-notifications"

const router = useRouter()
const authStore = useAuthStore()
const profileStore = useProfileStore()
const pushStore = usePushStore()
const messageStore = useMessageStore()
const pagesStore = usePagesStore()
const loading = ref(true)
const showError = ref(false)

pushStore.init()

onMounted(() => {
  authStore
    .checkLogin()
    .then((status) => {
      if (status) {
        router.push({ name: "Login" })
        profileStore.loadContactPerson()
        profileStore.loadProfile()
      }
    })
    .then(() => {
      const appStore = useAppStore()
      appStore.initApp().then(() => {

        showError.value = appStore.error.show

        const courseStore = useCourseStore()
        courseStore.getCourses()
        courseStore.getBookmarkedCourses()
        const profileStore = useProfileStore()
        profileStore.loadContactPerson()

        messageStore.startMessageService()

        const challengeStore = useChallengeStore()
        challengeStore.getChallenges()

        // Load pages
        pagesStore.getPages().then(() => {
          loading.value = false
        })

        const isPushNotificationsAvailable =
          Capacitor.isPluginAvailable("PushNotifications")
        //Request Permissions
        if (isPushNotificationsAvailable) {
          PushNotifications.requestPermissions().then(
            (permission: PermissionStatus) => {
              if (permission.receive === "granted") {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register().then()
              } else {
                // No permission for push granted
              }
            }
          )
        }

        //Registration in firebase
        if (isPushNotificationsAvailable) {
          PushNotifications.addListener(
            "registration",
            async (token: Token) => {
              //TODO:THE TOKEN IS RECEIVED AFTER A SUCCESSFUL AUTHENTICATION IS ACHIEVED
              pushStore.setToken(token.value)
            }
          )
        }

        // Subscribed notification
        if (isPushNotificationsAvailable) {
          PushNotifications.addListener(
            "pushNotificationReceived",
            async (notification: PushNotificationSchema) => {
              console.log(notification)
              //TODO: THIS IS WHERE THE NOTIFICATION IS CAPTURED (BACKGROUND)
            }
          )
        }

      })
    })
})
</script>

<style></style>
